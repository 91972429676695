import SectionMixin from '@/mixins/sectionMixin'

//formulajs calc
import {IRR, NOMINAL} from '@formulajs/formulajs'

export default {
    name: 'vtu-simulator',
    mixins: [SectionMixin],
    props:{
        type: String,
        title: String,
        subtitle: String,
        infoField: String,
        warningField: String,
        tooltip: {},
        questions: {},
    },
    
    data: ()=>{
        return {
            amount: Number,
            term: 365,

            eaRateCredit: Number,
            eaRateAccount: Number,

            safe: 0.26,
            months: Number,

            showError: false,
            vtuClosed: true,
            selectMonth: "",
            options: [
                { text: '12 Meses', value: 12 },
                { text: '18 Meses', value: 18 },
                { text: '24 Meses', value: 24 },
                { text: '30 Meses', value: 30 },
                { text: '36 Meses', value: 36 },
                { text: '42 Meses', value: 42 },
                { text: '48 Meses', value: 48 },
              ]
        }
    },
    beforeMount(){
        if(this.$store.state.page["vtu-credit"]){
            this.EAnumberCredit = this.$store.state.page["vtu-credit"].data.attributes.simulator.ea_rate
            this.eaRateCredit = this.EAnumberCredit
        }else if(this.$store.state.page["vtu-account"]){
            this.EAnumberAccount = this.$store.state.page["vtu-account"].data.attributes.simulator.ea_rate
            this.eaRateAccount = this.EAnumberAccount
        }
        this.amount = 0
    },
    mounted() {

        this.$el.addEventListener("submit", (evt)=>{
            evt.preventDefault()
            this.checkForm()
        })

        this.$refs["vtu-amount"].addEventListener("change", ()=>{

            this.inputValue = this.$refs["vtu-amount"].value
            let inputValueNumber = Number(this.inputValue).toLocaleString("en-US")
            let sourceLabel = document.querySelector('.form-group-label')
            if(this.inputValue){
                sourceLabel.innerHTML = inputValueNumber
            }else{
                sourceLabel.innerHTML = ''
            }

        })
    },

    methods: {
        checkForm: function (type) {
            this.inputValue = this.$refs["vtu-amount"].value

            if(type === 'credit'){
                if(this.inputValue && this.selectMonth){
                    this.amount = Number(this.inputValue)
                    this.months = this.selectMonth

                    if(this.amount > 50000000 ){
                        this.showError = true
                        this.vtuClosed = true
                    }else{
                        this.showError = false
                        this.vtuClosed = false
                    }
                }
            }else{
                if(this.inputValue){
                    this.amount = Number(this.inputValue)
                    this.showError = false
                    this.vtuClosed = false
                }
            }
        },
/*------------------------Credit----------------------------*/
        calculateIN: function(eaRateCredit){
            const nominal = Number((Math.pow(1 + eaRateCredit/100,1/12) - 1)*12);
            return nominal
        },
        calculateMV2: function(nominal){
            return (nominal/12)*100;
        },
        calculateSP: function(safe,MV2){
            return MV2+safe;
        },
        calculateEA2: function(SP){
            return Number(Math.pow(1+(SP/100),12)-1)
        },
        calculatePMT: function(amount,SP,months){
            return Number(amount * ( (Math.pow(1+SP/100, months)*SP/100) / (Math.pow(1+SP/100, months)-1) ) )
        },
        calculatePMTfor: function(PMT,months){
            return PMT * months;
        },
        calculatePMTminus: function(PMT,amount){
            return PMT - amount;
        },
        calculateS: function(safe,SP,PMTm){
            return (safe/SP)*PMTm;
        },
        calculateI: function(PMTm,secures){
            return PMTm-secures;
        },

/*------------------------Account----------------------------*/
        calculateEA: function(eaRateAccount){
            const toNominal = eaRateAccount/100;
            return toNominal;
        },

        calculateMV: function(EA, months = 12){
            return (NOMINAL(EA, months))*100;
        },

        calculateTable: function({amount, months = 12}){
            
            const monthsAverage = 30.5;
            const dailyTIR = ((this.MV/366)/100);
            const baseRte = 2588.88;
            const rteP = 0.07;

            let previusAmount = amount;

            const data = Array(months).fill().map((_, index) =>{
                const dailyInterest = previusAmount * dailyTIR;
                const monthlyInterest = dailyInterest * monthsAverage;
      
                previusAmount += monthlyInterest;

                let rte = 0;
                if(dailyInterest >= baseRte){
                    rte = (dailyInterest*rteP*monthsAverage)
                }else{
                    rte = 0
                }

                return {
                    amount: index === 0 ? amount : previusAmount,
                    interests: monthlyInterest,
                    rte: rte,
                }
            });
            return data;
        },

    },

    computed:{
        /*------------------------Credit----------------------------*/
        nominal(){
            return this.calculateIN(this.eaRateCredit)
        },
        MV2(){
            return this.calculateMV2(this.nominal)
        },
        SP(){
            return this.calculateSP(this.safe,this.MV2)
        },
        EA2(){
            return this.calculateEA2(this.SP)
        },
        PMT(){
            return this.calculatePMT(this.amount,this.SP,this.months)
        },
        PMTf(){
            return this.calculatePMTfor(this.PMT, this.months)
        },
        PMTm(){
            return this.calculatePMTminus(this.PMTf, this.amount)
        },
        secure(){
            return this.calculateS(this.safe,this.SP,this.PMTm)
        },
        interests(){
            return this.calculateI(this.PMTm, this.secure)
        },
        /*------------------------Account----------------------------*/
        EA(){
            return this.calculateEA(this.eaRateAccount)
        },
        MV(){
            return this.calculateMV(this.EA)
        },
        data(){
            return this.calculateTable({EA: this.EA, amount: this.amount})
        },
        Interest(){
            return this.data.reduce((acc, {interests}) => acc + interests, 0)
        },
        Rte(){
            return this.data.reduce((acc, {rte}) => acc + rte, 0).toLocaleString("en-US", {maximumFractionDigits: 0})
        },
        Total(){
            return (this.amount + this.Interest).toLocaleString("en-US", {maximumFractionDigits: 0})
        },
    },
}